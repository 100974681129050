import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { ContactStyle } from '../../styles/components/contact-us';
import { IContactFormValues } from '../common/forms/ContactForm';

export interface IContactMethodProps {
  type: string;
  label: string;
  value?: string;
}

export interface IContactProps {
  contactMethods: IContactMethodProps[];
  formLabels: {
    title: string;
    subTitle: string;
    name: string;
    email: string;
    message: string;
    submit: string;
    submitSuccessMessage: string;
    submitErrorMessage: string;
  };
  darkMode?: boolean;
  titleTagName?: string;
  submitResultType: 'success' | 'error' | undefined;
  onSubmit?: (formData: IContactFormValues) => Promise<void>;
}

const Contact = (props: IContactProps) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const router = useRouter();

  const onFormSubmitHandler = (formData: any) => {
    formData = { ...formData, language: router.locale || 'en' };
    props.onSubmit!(formData);
  };

  const contactMethodsIconsPaths: any = {
    phone: '/assets/images/icons/PhoneIcon.svg',
    email: '/assets/images/icons/EmailIcon.svg',
  };

  const Tag = props.titleTagName || 'h1';

  const getTitle = (title: string) => {
    return React.createElement(Tag, {}, title);
  };

  const DynamicContactForm = dynamic(() => import('../common/forms/ContactForm'));

  return (
    <div id="contact-us-form" className={`${ContactStyle.Contact} ${props.darkMode ? ContactStyle['dark-mode'] : ''}`}>
      <div className={ContactStyle['ContactDetails']}>
        {getTitle(props.formLabels.title)}
        <p>{props.formLabels.subTitle}</p>
      </div>
      <div className={ContactStyle['ContactWrap']}>
        <div className={ContactStyle['form']}>
          <DynamicContactForm
            formSubmit={onFormSubmitHandler}
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setButtonDisabled}
            darkMode={props.darkMode}
            submitResultType={props.submitResultType}
            labels={props.formLabels}
          />{' '}
        </div>
        <div className={ContactStyle['contactMethods']}>
          <div className={ContactStyle['contactMethodsDirect']}>
            {props.contactMethods.map((contactMethod: IContactMethodProps, index: number) => {
              return (
                <div className={ContactStyle['contactMethodItem']} key={index}>
                  <Image className={ContactStyle['contact-method-icon']} src={contactMethodsIconsPaths[contactMethod.type]} width={28} height={28} alt={contactMethod.type} />
                  <div>
                    <h3>{contactMethod.label}</h3>
                    {!!contactMethod.value ? <p>{contactMethod.value}</p> : null}
                  </div>
                </div>
              );
            })}
          </div>

          <div className={ContactStyle['socialIcons']}>
            <a href="https://www.facebook.com/igerent.ip" target="_system">
              <Image src="/assets/images/icons/FacebookIcon.svg" alt="Facebook" width={44} height={44} />
            </a>
            <a href="https://linkedin.com/company/igerent" target="_system">
              <Image src="/assets/images/icons/LinkedinIcon.svg" alt="Linkedin" width={44} height={44} />
            </a>
            <a href="https://twitter.com/igerent" target="_system">
              <Image src="/assets/images/icons/TwitterIcon.svg" alt="Twitter" width={44} height={44} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
